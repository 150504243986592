import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'



Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'feed-images' } },
    ...dashboard,
  
    
    
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (to.meta.isAuthRequired) {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const isLoggedIn = userData && Object.keys(userData).length !== 0
    if (isLoggedIn) {
      next()
    } else {
      router.push('/index').catch(() => {})
    }
  }

  return next()
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.pageTitle || 'BRIKS';  // Use the meta pageTitle or default to 'BRIKS'
  next();
});

export default router
